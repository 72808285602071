// src/firebase-config.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "sonner"; // Ensure Sonner is imported

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDll7aGD4GchhefQaOH4qjW66haI6Rn3WE",
  authDomain: "woapushnotification-c61cb.firebaseapp.com",
  projectId: "woapushnotification-c61cb",
  storageBucket: "woapushnotification-c61cb.appspot.com",
  messagingSenderId: "434626422398",
  appId: "1:434626422398:web:2d1b5c93125b70321fda2f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

/**
 * Function to request permission for push notifications and get FCM token
 */
export const requestFirebaseNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Getting the current FCM token
      const currentToken = await getToken(messaging, {
        vapidKey: "BGaEkjJXpWxkSiRShD1YWnua9UxduuH4mPqx8jQuQOL5nH9gduo5_wYFpOtsceD-gBID_AHRvkQ_r1v_9OSbuIU",
      });

      if (currentToken) {
        console.log("FCM Token successfully retrieved:", currentToken);
        localStorage.setItem("fcmToken", currentToken);
        return currentToken;
      } else {
        console.error("No registration token available. Request permission to generate one.");
      }
    } else {
      console.error("Notification permission denied.");
    }
  } catch (error) {
    if (error.code === "messaging/permission-blocked") {
      console.error("Notification permission was blocked. Please enable notifications from the browser settings.");
    } else {
      console.error("An error occurred while retrieving token:", error);
    }
    throw error;
  }
};

/**
 * Listener for receiving messages when the application is in the foreground
 */
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Foreground message received:", payload);
      // Display a toast notification using Sonner
      const { title, body } = payload.data;
      toast({
        title: title || "Notification",
        description: body || "You have a new message.",
        duration: 5000,
      });
      resolve(payload);
    });
  });
