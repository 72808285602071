// src/pages/Vehicles/AddNewBrand.js

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner";

function AddEditBrand() {
  const token = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { brandId } = useParams();
  const editBrand = location.state?.brand;
  const [brandDetails, setBrandDetails] = useState({
    brandName: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // If editing, set the existing vehicle details
  useEffect(() => {
    if (brandId && editBrand) {
      console.log("Edit: ", editBrand);
      setBrandDetails({
        brandName: editBrand.brandName
      });
    }
  }, [brandId, editBrand]);

  const validate = () => {
    const newErrors = {};

    // BrandName: Required and must be a string
    if (!brandDetails.brandName.trim()) {
      newErrors.brandName = "Brand name is required.";
    }else if (brandDetails.brandName.length > 20) {
      newErrors.lastName = "Brand Name cannot exceed 20 characters.";
      toast.error("Brand Name cannot exceed 20 characters.");
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    try {
      let response;
      if (brandId) {
        response = await vehicleAPI.editBrandAPI(brandDetails, brandId,token);
      } else {
        response = await vehicleAPI.addBrandAPI(brandDetails, token);
      }

      if (response) {
        toast.success("Brand added successfully!");
        setBrandDetails({
          brandName: "",
        });
        setErrors({});
        // Optionally, navigate away after successful submission
        navigate("/vehicles/brand");
      } else {
        console.log("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "brand" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {brandId ? "Edit" : "Add New"} Brand
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/brand");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mt-4">
                  {/* Brand Name */}
                  <div className="flex flex-col text-gray-600">
                    <label>Brand Name *</label>
                    <input
                      type="text"
                      name="brandName"
                      placeholder="Enter brand name"
                      value={brandDetails.brandName}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.brandName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.brandName && (
                      <span className="text-red-500 text-sm">
                        {errors.brandName}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    {brandId ? "Edit" : "Save "}
                  </button>
                  <button
                    type="reset"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={() => {
                      setBrandDetails({
                        brandName: "",
                      });
                      setErrors({});
                    }}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditBrand;
