// src/State/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  fcmToken: localStorage.getItem("fcmToken") || null,
  firstName: localStorage.getItem("firstName") || "",
  lastName: localStorage.getItem("lastName") || "",
  // Add other user-related states here
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
      localStorage.setItem("accessToken", action.payload);
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload);
    },
    setFcmToken(state, action) {
      state.fcmToken = action.payload;
      localStorage.setItem("fcmToken", action.payload);
    },
    setFirstName(state, action) {
      state.firstName = action.payload;
      localStorage.setItem("firstName", action.payload);
    },
    setLastName(state, action) {
      state.lastName = action.payload;
      localStorage.setItem("lastName", action.payload);
    },
    removeTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.fcmToken = null;
      state.firstName = "";
      state.lastName = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("fcmToken");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
    },
    setUserDetails(state, action) {
      const { accessToken, refreshToken, fcmToken, firstName, lastName } = action.payload;
      if (accessToken) {
        state.accessToken = accessToken;
        localStorage.setItem("accessToken", accessToken);
      }
      if (refreshToken) {
        state.refreshToken = refreshToken;
        localStorage.setItem("refreshToken", refreshToken);
      }
      if (fcmToken) {
        state.fcmToken = fcmToken;
        localStorage.setItem("fcmToken", fcmToken);
      }
      if (firstName) {
        state.firstName = firstName;
        localStorage.setItem("firstName", firstName);
      }
      if (lastName) {
        state.lastName = lastName;
        localStorage.setItem("lastName", lastName);
      }
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  setFcmToken,
  setFirstName,
  setLastName,
  removeTokens,
  setUserDetails,
} = userSlice.actions;

export default userSlice.reducer;
