// src/pages/AddEditDriver/AddEditDriver.js

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import api from "../../utils/API/driverApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import useAuthToken from "../../hooks/customHooks";

function AddEditDriver() {
  const location = useLocation();
  const { driverId } = useParams();
  const editDriver = location.state?.driver;
  console.log(editDriver, "Edit :");
  const navigate = useNavigate();
  const token = useAuthToken();

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    countryCode: "+1", // Default country code
    nationality: "",
    license: "",
    licenseDateOfExpiry: "",
    emiratesID: "",
    yearsOfExperience: "",
    availabilityStatus: "",
    driverLicense: null,
    emiratesId: null,
    // Excluding email from initial state for addition
  });

  const [errors, setErrors] = useState({});

  // useEffect to set form data when in edit mode
  useEffect(() => {
    if (driverId && editDriver) {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        ...editDriver,
        // Reset file inputs; users can re-upload if needed
        driverLicense: null,
        emiratesId: null,
      }));
    }
  }, [driverId, editDriver]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "driverLicense" || name === "emiratesId") {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: files[0],
      }));
    } else {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const validate = () => {
    const newErrors = {};

    // First Name Validation
    if (!userDetails.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!/^[A-Za-z\s]+$/.test(userDetails.firstName)) {
      newErrors.firstName = "First name should contain only letters.";
    } else if (userDetails.firstName.length > 20) {
      newErrors.firstName = "First name cannot exceed 20 characters.";
    }

    // Last Name Validation (Now Required)
    if (!userDetails.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    } else if (!/^[A-Za-z\s]+$/.test(userDetails.lastName)) {
      newErrors.lastName = "Last name should contain only letters.";
    } else if (userDetails.lastName.length > 20) {
      newErrors.lastName = "Last name cannot exceed 20 characters.";
    }

    // Country Code Validation
    if (!userDetails.countryCode) {
      newErrors.countryCode = "Country code is required.";
    }

    // Phone Number Validation
    if (!userDetails.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{7,15}$/.test(userDetails.phone)) {
      newErrors.phone = "Phone number must be between 7 to 15 digits.";
    }

    // Email Validation (Only when editing)
    if (driverId) {
      if (!editDriver.email || !editDriver.email.trim()) {
        newErrors.email = "Email address is required.";
      } else if (!/\S+@\S+\.\S+/.test(editDriver.email)) {
        newErrors.email = "Email address is invalid.";
      }
    }

    // Nationality Validation (Now Required)
    if (!userDetails.nationality.trim()) {
      newErrors.nationality = "Nationality is required.";
    } else if (userDetails.nationality.length > 30) {
      newErrors.nationality = "Nationality cannot exceed 30 characters.";
    }

    // License Number Validation (Now Required)
    if (!userDetails.license.trim()) {
      newErrors.license = "License number is required.";
    } else if (userDetails.license.length > 20) {
      newErrors.license = "License number cannot exceed 20 characters.";
    }

    // Emirates ID Validation (Now Required)
    if (!userDetails.emiratesID.trim()) {
      newErrors.emiratesID = "Emirates ID is required.";
    } else if (userDetails.emiratesID.length > 20) {
      newErrors.emiratesID = "Emirates ID cannot exceed 20 characters.";
    }

    // License Date of Expiry Validation (Now Required)
    if (!userDetails.licenseDateOfExpiry) {
      newErrors.licenseDateOfExpiry = "License Date of Expiry is required.";
    } else {
      const expiryDate = new Date(userDetails.licenseDateOfExpiry);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date
      if (isNaN(expiryDate.getTime())) {
        newErrors.licenseDateOfExpiry = "License Date of Expiry must be a valid date.";
      } else if (expiryDate < today) {
        newErrors.licenseDateOfExpiry = "License Date of Expiry cannot be in the past.";
      }
    }

    // Years of Experience Validation (Now Required)
    if (!userDetails.yearsOfExperience.trim()) {
      newErrors.yearsOfExperience = "Years of experience is required.";
    } else {
      const years = parseInt(userDetails.yearsOfExperience, 10);
      if (isNaN(years) || years < 0) {
        newErrors.yearsOfExperience = "Years of experience must be a positive integer.";
      }
    }

    // Availability Status Validation
    if (!userDetails.availabilityStatus) {
      newErrors.availabilityStatus = "Availability status is required.";
    } else if (!["true", "false"].includes(userDetails.availabilityStatus)) {
      newErrors.availabilityStatus = "Availability status must be either Available or Unavailable.";
    }

    // Set errors state
    setErrors(newErrors);

    // Trigger toast notifications only once per error
    Object.values(newErrors).forEach((errorMsg) => {
      toast.error(errorMsg);
    });

    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    try {
      const formData = new FormData();

      // Append all user details to formData
      Object.entries(userDetails).forEach(([key, value]) => {
        if (key === "driverLicense" || key === "emiratesId") {
          // Skip file uploads if not provided
          if (value) {
            formData.append(key, value);
          }
        } else {
          if (value !== null && value !== "") {
            formData.append(key, value);
          }
        }
      });

      // Include email only if editing
      if (driverId && editDriver.email) {
        formData.append("email", editDriver.email);
      }

      let response;
      if (driverId) {
        response = await api.updateDriverAPI(formData, userDetails.driverID, token);
      } else {
        response = await api.addDriverAPI(formData, token);
      }

      if (response.status !== 200 && response.status !== 201) {
        toast.error(response.data.message || "Something went wrong!");
      } else {
        console.log(response, "Response Status");
        toast.success(
          driverId
            ? "Driver updated successfully!"
            : "Driver added successfully!"
        );
        // Reset form only if not in edit mode
        if (!driverId) {
          setUserDetails({
            firstName: "",
            lastName: "",
            phone: "",
            countryCode: "+1",
            nationality: "",
            license: "",
            licenseDateOfExpiry: "",
            emiratesID: "",
            yearsOfExperience: "",
            availabilityStatus: "",
            driverLicense: null,
            emiratesId: null,
          });
        }
        setErrors({});
        // Optionally, navigate away after successful submission
        navigate("/driver");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  // Remove toast notifications from per-field validation to avoid duplicates
  const handleBlur = (e) => {
    validateField(e.target.name, e.target.value);
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value.trim()) {
          error = "First name is required.";
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "First name should contain only letters.";
        } else if (value.length > 20) {
          error = "First name cannot exceed 20 characters.";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          error = "Last name is required.";
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Last name should contain only letters.";
        } else if (value.length > 20) {
          error = "Last name cannot exceed 20 characters.";
        }
        break;
      case "phone":
        if (!/^\d{7,15}$/.test(value)) {
          error = "Phone number must be between 7 to 15 digits.";
        }
        break;
      case "countryCode":
        if (!value) {
          error = "Country code is required.";
        }
        break;
      case "nationality":
        if (!value.trim()) {
          error = "Nationality is required.";
        } else if (value.length > 30) {
          error = "Nationality cannot exceed 30 characters.";
        }
        break;
      case "license":
        if (!value.trim()) {
          error = "License number is required.";
        } else if (value.length > 20) {
          error = "License number cannot exceed 20 characters.";
        }
        break;
      case "emiratesID":
        if (!value.trim()) {
          error = "Emirates ID is required.";
        } else if (value.length > 20) {
          error = "Emirates ID cannot exceed 20 characters.";
        }
        break;
      case "licenseDateOfExpiry":
        if (!value) {
          error = "License Date of Expiry is required.";
        } else {
          const expiryDate = new Date(value);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Normalize today's date
          if (isNaN(expiryDate.getTime())) {
            error = "License Date of Expiry must be a valid date.";
          } else if (expiryDate < today) {
            error = "License Date of Expiry cannot be in the past.";
          }
        }
        break;
      case "yearsOfExperience":
        if (!value.trim()) {
          error = "Years of experience is required.";
        } else {
          const years = parseInt(value, 10);
          if (isNaN(years) || years < 0) {
            error = "Years of experience must be a positive integer.";
          }
        }
        break;
      case "availabilityStatus":
        if (!["true", "false"].includes(value)) {
          error = "Availability status must be either Available or Unavailable.";
        }
        break;
      case "email":
        if (driverId) {
          if (!value.trim()) {
            error = "Email address is required.";
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            error = "Email address is invalid.";
          }
        }
        break;
      default:
        break;
    }

    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    // Trigger toast only if there's an error and it's not already present
    if (error && !errors?.[name]) {
      toast.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "driver" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {driverId ? `Edit Driver` : `Add Driver`}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/driver");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6 mt-4">
                  {/* First Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.firstName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.firstName && (
                      <span className="text-red-500 text-sm">
                        {errors.firstName}
                      </span>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter last name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.lastName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.lastName && (
                      <span className="text-red-500 text-sm">
                        {errors.lastName}
                      </span>
                    )}
                  </div>

                  {/* Combined Country Code and Mobile Number */}
                  <div className="flex flex-col text-gray-600 col-span-1">
                    <label htmlFor="phone">Mobile *</label>
                    <div className="flex mt-1">
                      {/* Country Code Dropdown */}
                      <div className="w-1/4 mr-2">
                        <select
                          id="countryCode"
                          name="countryCode"
                          value={userDetails.countryCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className={`border rounded-xl p-2 w-full ${
                            errors.countryCode
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                        >
                          {/* Add more country codes as needed */}
                          <option value="+1">+1</option>
                          <option value="+44">+44</option>
                          <option value="+91">+91</option>
                          <option value="+971">+971</option>
                          {/* Example list; consider using a comprehensive list for production */}
                        </select>
                        {errors.countryCode && (
                          <span className="text-red-500 text-sm">
                            {errors.countryCode}
                          </span>
                        )}
                      </div>

                      {/* Mobile Number Input */}
                      <div className="w-3/4">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Enter Mobile Number"
                          value={userDetails.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className={`border rounded-xl p-2 w-full ${
                            errors.phone ? "border-red-500" : "border-gray-300"
                          }`}
                        />
                        {errors.phone && (
                          <span className="text-red-500 text-sm">
                            {errors.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Email - Only show when editing */}
                  {driverId && (
                    <div className="flex flex-col text-gray-600">
                      <label htmlFor="email">Email *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        value={editDriver.email || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        className={`border rounded-xl p-2 ${
                          errors.email ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {errors.email && (
                        <span className="text-red-500 text-sm">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  )}

                  {/* Nationality */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="nationality">Nationality *</label>
                    <input
                      type="text"
                      id="nationality"
                      name="nationality"
                      placeholder="Enter Nationality"
                      value={userDetails.nationality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.nationality
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.nationality && (
                      <span className="text-red-500 text-sm">
                        {errors.nationality}
                      </span>
                    )}
                  </div>

                  {/* License Number */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="license">License Number *</label>
                    <input
                      type="text"
                      id="license"
                      name="license"
                      placeholder="Enter License Number"
                      value={userDetails.license}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.license ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.license && (
                      <span className="text-red-500 text-sm">
                        {errors.license}
                      </span>
                    )}
                  </div>

                  {/* License Date of Expiry */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="licenseDateOfExpiry">License Date of Expiry *</label>
                    <input
                      type="date"
                      id="licenseDateOfExpiry"
                      name="licenseDateOfExpiry"
                      placeholder="License Date of Expiry"
                      value={userDetails.licenseDateOfExpiry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.licenseDateOfExpiry
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.licenseDateOfExpiry && (
                      <span className="text-red-500 text-sm">
                        {errors.licenseDateOfExpiry}
                      </span>
                    )}
                  </div>

                  {/* Emirates ID */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="emiratesID">Emirates ID *</label>
                    <input
                      type="text"
                      id="emiratesID"
                      name="emiratesID"
                      placeholder="Enter Emirates ID"
                      value={userDetails.emiratesID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.emiratesID ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.emiratesID && (
                      <span className="text-red-500 text-sm">
                        {errors.emiratesID}
                      </span>
                    )}
                  </div>

                  {/* Years of Experience */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="yearsOfExperience">Years of Experience *</label>
                    <input
                      type="number"
                      id="yearsOfExperience"
                      name="yearsOfExperience"
                      placeholder="Enter Years of Experience"
                      value={userDetails.yearsOfExperience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      min="0"
                      className={`border rounded-xl p-2 ${
                        errors.yearsOfExperience
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.yearsOfExperience && (
                      <span className="text-red-500 text-sm">
                        {errors.yearsOfExperience}
                      </span>
                    )}
                  </div>

                  {/* Availability Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="availabilityStatus">Availability Status *</label>
                    <select
                      id="availabilityStatus"
                      name="availabilityStatus"
                      value={userDetails.availabilityStatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.availabilityStatus
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Availability Status</option>
                      <option value="true">Available</option>
                      <option value="false">Unavailable</option>
                    </select>
                    {errors.availabilityStatus && (
                      <span className="text-red-500 text-sm">
                        {errors.availabilityStatus}
                      </span>
                    )}
                  </div>

                  {/* File Uploads */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="driverLicense">Driver License</label>
                    <input
                      type="file"
                      id="driverLicense"
                      name="driverLicense"
                      onChange={handleChange}
                      accept=".pdf,.jpg,.jpeg,.png"
                      className={`border rounded-xl p-2 ${
                        errors.driverLicense
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.driverLicense && (
                      <span className="text-red-500 text-sm">
                        {errors.driverLicense}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="emiratesId">Emirates ID Document</label>
                    <input
                      type="file"
                      id="emiratesId"
                      name="emiratesId"
                      onChange={handleChange}
                      accept=".pdf,.jpg,.jpeg,.png"
                      className={`border rounded-xl p-2 ${
                        errors.emiratesId
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.emiratesId && (
                      <span className="text-red-500 text-sm">
                        {errors.emiratesId}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  >
                    {driverId ? "Update" : "Save"}
                  </button>
                  <button
                    type="reset"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={() => {
                      setUserDetails({
                        firstName: "",
                        lastName: "",
                        phone: "",
                        countryCode: "+1",
                        nationality: "",
                        license: "",
                        licenseDateOfExpiry: "",
                        emiratesID: "",
                        yearsOfExperience: "",
                        availabilityStatus: "",
                        driverLicense: null,
                        emiratesId: null,
                      });
                      setErrors({});
                      // If resetting from edit mode, navigate away
                      if (driverId) {
                        navigate("/driver");
                      }
                    }}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditDriver;
