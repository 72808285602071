// src/Pages/RevenueReport/RevenueReport.jsx

import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Pagination from "../../Components/Pagination"; // Ensure this component exists
import apis from "../../utils/API/driverApis"; // Updated import path
import { toast } from "sonner"; // Assuming you use Sonner for toasts
import useAuthToken from "../../hooks/customHooks"; // Ensure this is the correct path
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const RevenueReport = () => {
  // Custom hook to retrieve token
  const token = useAuthToken();

  // State variables
  const [revenueData, setRevenueData] = useState([]); // Fetched revenue data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [exporting, setExporting] = useState(false); // Exporting state

  // State for filters
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for filtered data
  const [filteredData, setFilteredData] = useState([]);

  // Effect to fetch revenue data whenever year or month changes
  useEffect(() => {
    const fetchRevenue = async () => {
      setLoading(true);
      setError(null);
      try {
        if (!token) {
          throw new Error("Authentication token not found.");
        }
        const response = await apis.getRevenue(selectedYear, selectedMonth, token);
        console.log("API Response:", response.data); // Debugging line
        if (response.data.success) {
          setRevenueData(response.data.Revenue || []);
        } else {
          setError(response.data.message || "Failed to fetch revenue data.");
          toast.error(response.data.message || "Failed to fetch revenue data.");
          setRevenueData([]); // Reset to empty array on failure
        }
      } catch (err) {
        console.error("Error fetching revenue data:", err);
        setError("An error occurred while fetching revenue data.");
        toast.error("An error occurred while fetching revenue data.");
        setRevenueData([]); // Ensure it's an array
      } finally {
        setLoading(false);
      }
    };

    fetchRevenue();
  }, [selectedYear, selectedMonth, token]);

  // Effect to filter data based on search, driver, and date range
  useEffect(() => {
    let data = [...revenueData];

    // Filter by search query
    if (searchQuery.trim() !== "") {
      data = data.filter((item) =>
        [item.Driver.firstName + " " + item.Driver.lastName, item.paymentType]
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    // Filter by driver
    if (selectedDriver !== "") {
      data = data.filter(
        (item) =>
          `${item.Driver.firstName} ${item.Driver.lastName}`.toLowerCase() ===
          selectedDriver.toLowerCase()
      );
    }

    // Filter by date range
    if (fromDate) {
      const from = new Date(fromDate);
      data = data.filter((item) => new Date(item.createdAt) >= from);
    }

    if (toDate) {
      const to = new Date(toDate);
      data = data.filter((item) => new Date(item.createdAt) <= to);
    }

    setFilteredData(data);
    setCurrentPage(1); // Reset to first page when filters change
  }, [searchQuery, selectedDriver, fromDate, toDate, revenueData]);

  // Calculate the total number of pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredData.length / itemsPerPage);
  }, [filteredData.length, itemsPerPage]);

  // Get the data for the current page
  const displayedData = useMemo(() => {
    if (!filteredData || !Array.isArray(filteredData)) return [];
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle driver select change
  const handleDriverChange = (e) => {
    setSelectedDriver(e.target.value);
  };

  // Handle reset functionality
  const handleReset = () => {
    setSearchQuery("");
    setSelectedDriver("");
    setSelectedYear(new Date().getFullYear());
    setSelectedMonth(new Date().getMonth() + 1);
    setFromDate("");
    setToDate("");
  };

  // Get unique driver names for the driver filter
  const uniqueDrivers = useMemo(() => {
    if (!revenueData || !Array.isArray(revenueData)) return [];
    return [...new Set(revenueData.map((item) => `${item.Driver.firstName} ${item.Driver.lastName}`))];
  }, [revenueData]);

  // Compute summary statistics
  const totalRevenue = useMemo(() => {
    return filteredData
      .reduce((acc, item) => acc + Number(item.fare || 0), 0)
      .toFixed(2);
  }, [filteredData]);

  // POS Revenue Calculation
  const posRevenue = useMemo(() => {
    return filteredData
      .filter((item) => item.paymentType && item.paymentType.toLowerCase() === "pos")
      .reduce((acc, item) => acc + Number(item.fare || 0), 0)
      .toFixed(2);
  }, [filteredData]);

  // Cash Revenue Calculation
  const cashRevenue = useMemo(() => {
    return filteredData
      .filter((item) => item.paymentType && item.paymentType.toLowerCase() === "cash")
      .reduce((acc, item) => acc + Number(item.fare || 0), 0)
      .toFixed(2);
  }, [filteredData]);

  // Wallet Revenue Calculation
  const walletRevenue = useMemo(() => {
    return filteredData
      .filter((item) => item.paymentType && item.paymentType.toLowerCase() === "wallet")
      .reduce((acc, item) => acc + Number(item.fare || 0), 0)
      .toFixed(2);
  }, [filteredData]);

  const totalRides = useMemo(() => {
    return filteredData.length;
  }, [filteredData]);

  // Removed totalDrivers and related calculations

  const totalCustomers = useMemo(() => {
    return [...new Set(filteredData.map((item) => item.customerId))].length;
  }, [filteredData]);

  // Function to export data to Excel
  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data available to export.");
      return;
    }

    setExporting(true);
    try {
      // Map the filteredData to include formatted dates
      const exportData = filteredData.map((item) => ({
        "Driver Name":
          item.Driver && item.Driver.firstName && item.Driver.lastName
            ? `${item.Driver.firstName} ${item.Driver.lastName}`
            : "N/A",
        "Payment Type": item.paymentType || "N/A",
        "Trip ID": item.tripId || "N/A",
        "Created At": item.createdAt
          ? new Date(item.createdAt).toLocaleString()
          : "N/A",
        "Total Revenue (AED)": item.fare !== undefined ? `AED ${item.fare.toFixed(2)}` : "N/A",
      }));

      // Create a worksheet
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Revenue Report");

      // Generate a binary string representation of the workbook
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Create a Blob from the binary string
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Save the Blob as an Excel file
      saveAs(blob, `Revenue_Report_${selectedYear}_${selectedMonth}.xlsx`);

      // Show success toast
      toast.success("Exported successfully!");
    } catch (err) {
      console.error("Error exporting data:", err);
      toast.error("An error occurred while exporting data.");
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "revenue", sub: null }} />
        {/* Main content area */}
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            {/* Header */}
            <div className="flex flex-col bg-white rounded-md my-4 pb-12">
              {/* Title */}
              <div className="bg-woagreen p-4 rounded-t-md mb-4">
                <h2 className="text-white text-lg">Revenue Report</h2>
              </div>

              {/* Search and Filter Section */}
              <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mt-4 mx-6">
                {/* Year Selector */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="year">Year</label>
                  <select
                    id="year"
                    className="p-2 border rounded-md"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                  >
                    {/* Generate year options, e.g., last 5 years */}
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Month Selector */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="month">Month</label>
                  <select
                    id="month"
                    className="p-2 border rounded-md"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                  >
                    {/* Month options */}
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                      <option key={month} value={month}>
                        {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Driver Select */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="driver">Driver</label>
                  <select
                    id="driver"
                    className="p-2 border rounded-md"
                    value={selectedDriver}
                    onChange={handleDriverChange}
                  >
                    <option value="">All Drivers</option>
                    {/* Populate driver options dynamically */}
                    {uniqueDrivers && uniqueDrivers.length > 0 ? (
                      uniqueDrivers.map((driverName) => (
                        <option key={driverName} value={driverName}>
                          {driverName}
                        </option>
                      ))
                    ) : (
                      <option value="">No Drivers Available</option>
                    )}
                  </select>
                </div>

                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search</label>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="p-2 border rounded-md"
                    placeholder="Driver or Payment Type"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>

                {/* From Date */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="fromDate">From Date</label>
                  <input
                    type="date"
                    id="fromDate"
                    className="p-2 border rounded-md"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    max={toDate || undefined} // Optional: Prevent selecting a fromDate after toDate
                  />
                </div>

                {/* To Date */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="toDate">To Date</label>
                  <input
                    type="date"
                    id="toDate"
                    className="p-2 border rounded-md"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    min={fromDate || undefined} // Optional: Prevent selecting a toDate before fromDate
                  />
                </div>
              </div>

              {/* Filter, Reset, and Export Buttons */}
              <div className="flex gap-2 mt-4 mx-6">
                {/* Existing Filter Button */}
                <button
                  className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-500 transition duration-300"
                  onClick={() => { }}
                  disabled
                  title="Filters are applied automatically"
                >
                  Filter
                </button>
                {/* Reset Button */}
                <button
                  className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-500 transition duration-300"
                  onClick={handleReset}
                >
                  Reset
                </button>
                {/* **Export Button** */}
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition duration-300 flex items-center"
                  onClick={exportToExcel}
                  disabled={filteredData.length === 0 || exporting}
                  title="Export current data to Excel"
                >
                  {exporting ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                      Exporting...
                    </>
                  ) : (
                    "Export to Excel"
                  )}
                </button>
              </div>
            </div>

            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-4">
              <div className="bg-white flex flex-col justify-evenly p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">TOTAL REVENUE</h3>
                <p className="text-md">AED {totalRevenue}</p>
              </div>
              {/* POS Revenue Card */}
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">POS REVENUE</h3>
                <p className="text-md">AED {posRevenue}</p>
              </div>
              {/* Cash Revenue Card */}
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">CASH REVENUE</h3>
                <p className="text-md">AED {cashRevenue}</p>
              </div>
              {/* Wallet Revenue Card */}
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">WALLET REVENUE</h3>
                <p className="text-md">AED {walletRevenue}</p>
              </div>
              {/* TOTAL RIDES */}
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">TOTAL RIDES</h3>
                <p className="text-md">{totalRides}</p>
              </div>
              {/* TOTAL CUSTOMERS */}
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">TOTAL CUSTOMERS</h3>
                <p className="text-md">{totalCustomers}</p>
              </div>
            </div>

            {/* Revenue Table */}
            <div className="bg-white p-4 rounded-md">
              {loading ? (
                <div className="flex justify-center items-center py-10">
                  <p className="text-gray-500">Loading revenue data...</p>
                </div>
              ) : error ? (
                <div className="flex justify-center items-center py-10">
                  <p className="text-red-500">{error}</p>
                </div>
              ) : (
                <>
                  {filteredData.length === 0 ? (
                    <div className="text-center my-4 text-gray-700">
                      No results found under selected criteria.
                    </div>
                  ) : (
                    <div className="overflow-x-auto">
                      <table className="min-w-full border-collapse">
                        <thead className="text-sm text-gray-700 bg-gray-100">
                          <tr>
                            <th className="p-2 text-left">Driver Name</th>
                            <th className="p-2 text-left">Payment Type</th>
                            <th className="p-2 text-left">Trip ID</th>
                            <th className="p-2 text-left">Created At</th>
                            <th className="p-2 text-left">Total Revenue (AED)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedData.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                              <td className="p-2 text-left">
                                {item.Driver && item.Driver.firstName && item.Driver.lastName
                                  ? `${item.Driver.firstName} ${item.Driver.lastName}`
                                  : "N/A"}
                              </td>
                              <td className="p-2 text-left capitalize">{item.paymentType || "N/A"}</td>
                              <td className="p-2 text-left">{item.tripId || "N/A"}</td>
                              <td className="p-2 text-left">
                                {item.createdAt
                                  ? new Date(item.createdAt).toLocaleString()
                                  : "N/A"}
                              </td>
                              <td className="p-2 text-left">
                                {item.fare !== undefined ? `${item.fare.toFixed(2)}` : "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* Pagination and Showing Text */}
                  {filteredData.length > 0 && (
                    <div className="flex justify-between items-center mt-4 mx-6">
                      {/* Showing X to Y of Z */}
                      <p>
                        Showing{" "}
                        {filteredData.length > 0
                          ? `${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(
                            currentPage * itemsPerPage,
                            filteredData.length
                          )}`
                          : "0"}{" "}
                        of {filteredData.length}
                      </p>

                      {/* Pagination Component */}
                      {totalPages > 1 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageClick}
                          onFirstPage={handleFirstPage}
                          onLastPage={handleLastPage}
                          onPreviousPage={handlePreviousPage}
                          onNextPage={handleNextPage}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueReport;
