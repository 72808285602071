// src/Components/RidesTable.jsx

import React from "react";

export default function RidesTable({ headers, rides, onAssign, type }) {
  // Mapping between header titles and ride object keys
  const headerKeyMap = {
    "Ride ID": "id",
    "Customer ID": "customerId",
    "Pickup": "pickup",
    "Drop": "drop",
    "Vehicle Type": "vehicleType",
    "Distance (km)": "distance",
    "Scheduled Date & Time": "scheduledDateTime",
    "Rescheduled Date & Time": "rescheduledDateTime",
    "Fare": "fare",
    "Driver ID": "driverID",
    "Status": "rideStatus",
    // Add more mappings as needed
  };

  return (
    <div className="w-full overflow-x-auto bg-white p-4 rounded shadow">
      {rides.length === 0 ? (
        <p className="text-center text-gray-500">No {type} rides available.</p>
      ) : (
        <table className="w-full text-left border-collapse rounded bg-white" cellSpacing="0">
          <thead>
            <tr className="border-b border-slate-300">
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="h-12 px-6 text-sm font-medium stroke-slate-700 text-slate-700"
                >
                  {header}
                </th>
              ))}
              {onAssign && (
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium stroke-slate-700 text-slate-700"
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rides.map((ride) => (
              <tr key={ride.id} className="border-b border-slate-200">
                {headers.map((header) => {
                  const key = headerKeyMap[header];
                  let displayValue = ride[key];

                  // Format date fields
                  if (key === "scheduledDateTime" || key === "rescheduledDateTime") {
                    displayValue = displayValue
                      ? new Date(displayValue).toLocaleString()
                      : "N/A";
                  }

                  // Handle null or undefined values
                  if (displayValue === null || displayValue === undefined) {
                    displayValue = "N/A";
                  }

                  return (
                    <td
                      key={`${ride.id}-${key}`}
                      className="h-12 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500"
                    >
                      {displayValue}
                    </td>
                  );
                })}
                {onAssign && (
                  <td className="h-12 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    <button
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-200"
                      onClick={() => onAssign(ride)}
                    >
                      Assign
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
