// src/utils/apis.js

import {
  ADD_DRIVER,
  ADMIN_ROUTE,
  BASE_URL,
  DELETE_DRIVER,
  EDIT_DRIVER,
  GET_ALL_DRIVERS,
  GET_REVENUE, // New endpoint
} from "./apiEndPoint";
import {
  apiDeleteFetcher,
  apiPostFetcher,
  postFormDataAPI,
  updateFormDataAPI,
  apiGetFetcher,
} from "./apiFetcher";

/**
 * Fetches all drivers with pagination using path parameters.
 * @param {number} pageNumber - The page number to retrieve.
 * @param {number} itemsPerPage - The number of items per page.
 * @param {object} payload - Additional data to send with the request.
 * @param {String} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const getAllDrivers = (pageNumber, itemsPerPage, payload, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${GET_ALL_DRIVERS}/${pageNumber}/${itemsPerPage}`;
  return apiPostFetcher(url, payload, token);
};

/**
 * Deletes a driver by ID.
 * @param {string} driverID - The ID of the driver to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const deleteDriverAPI = async (driverID, page, items, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${DELETE_DRIVER}/${driverID}/${page}/${items}`;
  return apiDeleteFetcher(url, token);
};

/**
 * Adds a driver using formdata.
 * @param {FormData} formData - Details of the driver to be added.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const addDriverAPI = async (formData, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${ADD_DRIVER}`;
  return postFormDataAPI(url, formData, token);
};

/**
 * Updates a driver using formdata.
 * @param {FormData} formData - Details of the driver to be updated.
 * @param {string} driverID - The ID of the driver to update.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const updateDriverAPI = async (formData, driverID, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${EDIT_DRIVER}/${driverID}`;
  return updateFormDataAPI(url, formData, token);
};

/**
 * Fetches available drivers based on vehicle model and pickup location.
 * @param {string} vehicleModel - The model of the vehicle.
 * @param {number} pickupLatitude - The latitude of the pickup location.
 * @param {number} pickupLongitude - The longitude of the pickup location.
 * @param {string} token - The longitude of the pickup location.
 * @returns — An object containing data, status, and error.
 */
const adminGetDrivers = async (vehicleModel, pickupLatitude, pickupLongitude, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}/get-nearby-drivers`;
  const payload = {
    vehicleModel,
    pickupLatitude,
    pickupLongitude,
  };
  return apiPostFetcher(url, payload, token);
};

/**
 * Creates a new ride.
 * @param {object} rideData - The ride details.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const adminCreateRide = async (rideData, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}/create-ride`;
  return apiPostFetcher(url, rideData, token);
};

/**
 * Fetches driver notifications with pagination.
 * @param {number} page - The page number to retrieve.
 * @param {number} pageSize - The number of items per page.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const getDriverNotifications = (page, pageSize, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}/driver-notifications/${page}/${pageSize}`;
  return apiGetFetcher(url, token);
};

/**
 * Fetches revenue data for a specific year and month.
 * @param {number} year - The year for which to fetch revenue.
 * @param {number} month - The month for which to fetch revenue.
 * @param {string} token - Bearer Token for authentication.
 * @returns {Promise<object>} - An object containing data, status, and error.
 */
const getRevenue = async (year, month, token) => {
  const url = `${BASE_URL}${ADMIN_ROUTE}${GET_REVENUE}/${year}/${month}`;
  return apiGetFetcher(url, token);
};

// Assigning the functions to an object before exporting
const apiFnc = {
  getAllDrivers,
  deleteDriverAPI,
  addDriverAPI,
  updateDriverAPI,
  adminGetDrivers,
  adminCreateRide,
  getDriverNotifications,
  getRevenue, // Newly added function
};

export default apiFnc;
