// src/Components/Navbar.jsx

import React from "react";
import { useSelector } from "react-redux"; // Import useSelector
import img from "../Asset/profile.png";
import useLogout from "../hooks/useLogout";

function Navbar() {
  const handleLogout = useLogout();

  // Access firstName and lastName from Redux store
  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);

  // Determine the display name
  const displayName =
    firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName
      ? firstName
      : lastName
      ? lastName
      : "User"; // Fallback to "User" if names are not available

  return (
    <nav className="bg-white shadow-md py-4 h-[8vh] px-6 w-full flex justify-between items-center fixed top-0 z-10">
      <div className="flex items-center">
        <h1 className="text-green-600 text-2xl font-bold mb-2">WOA</h1>
      </div>
      <div className="flex items-center">
        {/* Display the user's full name */}
        <span className="text-gray-500 mr-4">Hello, {displayName}! 👋</span>
        <img
          src={img}
          alt="User Avatar"
          className="rounded-full w-10 h-10"
        />
        <button
          className="border border-red-800 text-sm font-semibold rounded-md ml-8 p-1 text-red-800"
          onClick={handleLogout} // Simplified onClick handler
        >
          Logout
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
