// src/Components/AssignedRidesTable.jsx

import React from "react";
import RidesTable from "./RidesTable";

export default function AssignedRidesTable({ rides }) {
  const headers = [
    "Ride ID",
    "Customer ID",
    "Pickup",
    "Drop",
    "Vehicle Type",
    "Distance (km)",
    "Fare",
    "Driver ID",
    "Scheduled Date & Time",
    "Action",
  ];

  return (
    <RidesTable
      headers={headers}
      rides={rides}
      type="assigned"
    />
  );
}
