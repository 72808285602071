import {
    ADD_CUSTOMER,
    ADMIN_ROUTE,
    BASE_URL,
    DELETE_CUSTOMER,
    EDIT_CUSTOMER,
    GET_ALL_CUSTOMERS,
} from "./apiEndPoint";
import { apiDeleteFetcher, apiPostFetcher, apiGetFetcher, apiPutFetcher } from "./apiFetcher";

/**
 * Fetches all customers with pagination using path parameters.
 * @param {string} pageNumber - The page number to retrieve.
 * @param {string} items- Item to be sent.
 * @param {object} payload - The number of items per page.
 * @param {String} token - Optional filter or search criteria.
 * @returns — An object containing data, status, and error.
 */
export const getAllCustomers = (page, itemsPerPage, payload,token) => {
    const url = `${BASE_URL}${ADMIN_ROUTE}${GET_ALL_CUSTOMERS}/${page}/${itemsPerPage}`;
    return apiPostFetcher(url, payload,token);
};

/**
 * Deletes a customer by ID.
 * @param {string} customerID - The ID of the customer to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const deleteCustomerAPI = async (customerID, page, items, token) => {
    console.log("Deleting customer with ID:", customerID, "Page:", page, "Items:", items); // Debugging line
    const url = `${BASE_URL}${ADMIN_ROUTE}${DELETE_CUSTOMER}/${customerID}/${page}/${items}`;
    return apiDeleteFetcher(url, token);
  };
  

/**
 * Adds a customer using JSON.
 * @param {object} customerData - Details of the customer to be added.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const addCustomerAPI = async (customerData,token) => {
    const url = `${BASE_URL}${ADMIN_ROUTE}${ADD_CUSTOMER}`;
    return apiPostFetcher(url, customerData,token);
};

/**
 * Updates a customer's details using JSON.
 * @param {object} customerData - Details of the customer to be edited.
 * @param {string} customerID - The ID of the customer to edit.
 * @param {string} token - Bearer Token.
 * @returns — An object containing data, status, and error.
 */
const updateCustomerAPI = async (customerData, customerID,token) => {
    const url = `${BASE_URL}${ADMIN_ROUTE}${EDIT_CUSTOMER}/${customerID}`;
    return apiPutFetcher(url, customerData,token);
};

// Assigning the functions to an object before exporting
const customerApiFnc = {
    getAllCustomers,
    deleteCustomerAPI,
    addCustomerAPI,
    updateCustomerAPI,
};

export default customerApiFnc;
