// src/Pages/DriverList/DriverList.jsx

import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import StatusBadge from "../../Components/StatusBadge";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import { useNavigate } from "react-router-dom";
import apis from "../../utils/API/driverApis";
import { toast } from "sonner";
import ConfirmModal from "../../Components/ConfirmModal"; // Import the ConfirmModal component
import useAuthToken from "../../hooks/customHooks";

function DriverList() {
  const navigate = useNavigate();
  const token = useAuthToken();


  // State for drivers data
  const [drivers, setDrivers] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for search and filter inputs
  const [filterValues, setFilterValues] = useState({
    searchQuery: "",
    status: "",
  });

  // State for applied search and filter payload
  const [searchPayload, setSearchPayload] = useState({
    searchQuery: "",
    status: "",
  });

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);

  // Fetch drivers whenever currentPage, itemsPerPage, or searchPayload changes
  useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await apis.getAllDrivers(
          currentPage,
          itemsPerPage,
          searchPayload,
          token
        
        );
        console.log("DATA: ", data.data.drivers);
        setDrivers(data.data.drivers);
        setTotalDrivers(data.data.totalDrivers);
        setTotalPages(data.data.totalPages);
      } catch (err) {
        console.log("Fetching Drivers Error: ", err);
        setError("Failed to fetch drivers.");
      } finally {
        setLoading(false);
      }
    };

    fetchDrivers();
  }, [currentPage, itemsPerPage, searchPayload]);

  const handleEdit = (driverId, driver) => {
    navigate(`/edit-driver/${driverId}`, { state: { driver } });
  };

  const handleAddDriver = () => {
    navigate("/add-driver");
  };

  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => {
    setCurrentPage(1);
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setFilterValues((prev) => ({ ...prev, searchQuery: e.target.value }));
  };

  // Handle status filter change
  const handleStatusFilterChange = (e) => {
    setFilterValues((prev) => ({ ...prev, status: e.target.value }));
  };

  // Handle filter button click
  const handleFilter = () => {
    setSearchPayload(filterValues);
    setCurrentPage(1); // Reset to first page on filter
  };

  // Handle reset button click
  const handleReset = () => {
    setFilterValues({ searchQuery: "", status: "" });
    setSearchPayload({ searchQuery: "", status: "" });
    setCurrentPage(1);
  };

  // Handle Delete Driver with modal
  const handleDeleteDriver = (driverID) => {
    setDriverToDelete(driverID);
    setIsModalOpen(true);
  };

  // Confirm deletion
  const confirmDeleteDriver = async () => {
    if (!driverToDelete) return;
    try {
      const result = await apis.deleteDriverAPI(driverToDelete, currentPage, itemsPerPage,token);
      if(result.status!==200){
        toast.error("Failed to delete driver.");
      }else if(result.status===200){
        toast.success("Driver deleted successfully!");
      }
      
    } catch (err) {
      toast.error("Failed to delete driver.");
      // Revert to previous state in case of error
    } finally {
      setLoading(false);
      setIsModalOpen(false);
      setDriverToDelete(null);
    }
  };

  // Cancel deletion
  const cancelDeleteDriver = () => {
    setIsModalOpen(false);
    setDriverToDelete(null);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "driver" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Driver List
            </h1>

         
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Name | Mobile | Email"
                    className="border border-gray-300 rounded p-2 w-96"
                    value={filterValues.searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>

                {/* Status Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    className="border border-gray-300 rounded p-2"
                    value={filterValues.status}
                    onChange={handleStatusFilterChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="enabled">Enabled</option>
                    <option value="disabled">Disabled</option>
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Driver List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Driver List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={handleAddDriver}
                >
                  Add Driver
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                <span>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalDrivers)} of{" "}
                  {totalDrivers}
                </span>
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Driver List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Name</div>
                <div className="flex-1 text-center">Mobile</div>
                {/* <div className="flex-1 basis-40 text-left">Email</div> */}
                <div className="flex-1 text-left">Status</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Driver Cards */}
              {loading ? (
                <div className="flex justify-center items-center p-4">
                  Loading drivers...
                </div>
              ) : error ? (
                <div className="flex justify-center items-center p-4 text-red-500">
                  {error}
                </div>
              ) : Array.isArray(drivers) && drivers.length > 0 ? (
                drivers.map((driver) => (
                  <div
                    key={driver.driverID}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 text-left">{driver.driverID}</div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {driver.firstName} {driver.lastName}
                    </div>
                    <div className="flex-1 text-center">{driver.phone}</div>
                    {/* <div
                      className="flex-1 basis-40 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {driver?.email}
                    </div> */}
                    <div className="flex-1 text-left">
                      <StatusBadge status={driver.availabilityStatus} />
                    </div>
                    <div className="flex-1 text-left">
                      {new Date(driver.createdAt).toLocaleDateString()}
                    </div>
                    <div className="flex-1 flex justify-end">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-l-lg p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit driver ${driver.driverID}`}
                        onClick={() => handleEdit(driver.driverID, driver)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-r-lg p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete driver ${driver.driverID}`}
                        onClick={() => handleDeleteDriver(driver.driverID)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No drivers found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalDrivers)} of{" "}
                  {totalDrivers}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm Deletion Modal */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message="Are you sure you want to delete this driver? This action cannot be undone."
        onConfirm={confirmDeleteDriver}
        onCancel={cancelDeleteDriver}
      />
    </>
  );
}

export default DriverList;
